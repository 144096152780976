<template>
    <section id="clients" class="section-bg mb-5" style="text-align: center;">
        <!-- <marquee direction="left" behavior="scroll" scrolldelay='5' scrollspeed='true' style="webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        min-width: 100%;
        max-height: 100px;
        -webkit-animation: scroll-x 60s linear infinite;
        animation: scroll-x 60s linear infinite;" scrollamount="2"> -->
        <marquee direction="left" behavior="scroll" scrolldelay="5" scrollspeed="true" style="margin-top: 0px; min-width: 100%; max-height: 100px; -webkit-animation: scroll-x 60s linear infinite; animation: scroll-x 60s linear infinite;" scrollamount="6">
        <!-- <marquee direction="left" style="width: 720px; animation: marquee 1s linear infinite" scrollamount="5"> -->
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>
          
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>
          
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>
          
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/client-mandiri.png" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/alfamart.png" style="width: auto; max-height: 120px; padding-top: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-indihome.png" style="width: auto; max-height: 24px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bni.png" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-bsi.png" style="width: auto; max-height: 50px; padding-bottom: 10px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prodia.png" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kalbe.png" class="img-fluid" alt="" style="width: auto; max-height: 36px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-alfamidi.png" class="img-fluid" alt="" style="width: auto; max-height: 110px; padding-bottom: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/informa.png" class="img-fluid" alt="" style="width: auto; max-height: 46px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kai.png" alt="" class="img-fluid" style="width: auto; max-height: 25px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/dukcapil.png" class="img-fluid" alt="" style="width: auto; max-height: 56px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bpjs-tk.png" class="img-fluid" alt="" style="width: auto; max-height: 28px;"></span>
        </marquee>
        <marquee direction="right" behavior="scroll" scrolldelay="5" scrollspeed="true" style="margin-top: 50px; min-width: 100%; max-height: 100px; -webkit-animation: scroll-x 60s linear infinite; animation: scroll-x 60s linear infinite;" scrollamount="6">
        <!-- <marquee direction="right" behavior="scroll" scrolldelay='5' scrollspeed='true' style="margin-top: 50px; webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        min-width: 100%;
        max-height: 100px;
        -webkit-animation: scroll-x 60s linear infinite;
        animation: scroll-x 60s linear infinite;" scrollamount="2"> -->
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-telkomsel-baru.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bumn.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mitra-keluarga.png" class="img-fluid" alt="" style="height: 120px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/biofarma.png" class="img-fluid" alt="" style="width: auto; max-height: 55px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bank-aladin.png" class="img-fluid" alt="" style="height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/danone-academy.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/logo-sicepat.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/nestle.png" class="img-fluid" alt="" style="height: 90px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mega-finance.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/indomaret.png" class="img-fluid" alt="" style="width: auto; max-height: 80px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/baf.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/xxi.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
        </marquee>
        <marquee direction="left" behavior="scroll" scrolldelay="5" scrollspeed="true" style="margin-top: 50px; min-width: 100%; max-height: 100px; -webkit-animation: scroll-x 60s linear infinite; animation: scroll-x 60s linear infinite;" scrollamount="6">
        <!-- <marquee direction="left" behavior="scroll" scrolldelay='5' scrollspeed='true' style="margin-top: 50px; webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        min-width: 100%;
        max-height: 100px;
        -webkit-animation: scroll-x 60s linear infinite;
        animation: scroll-x 60s linear infinite;" scrollamount="2"> -->
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>

          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bri.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/mcd.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kfc.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/prudential.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bi.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kominfo.png" class="img-fluid" alt="" style="width: auto; max-height: 70px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/pln.png" class="img-fluid" alt="" style="width: auto; max-height: 60px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/bei.png" class="img-fluid" alt="" style="width: auto; max-height: 45px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenkes.png" class="img-fluid" alt="" style="width: auto; max-height: 40px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/ojk.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/noice.png" class="img-fluid" alt="" style="width: auto; max-height: 30px;"></span>
          <span class="loop-content" style="margin-right: 60px;"><img src="@/assets/clients/kemenparekraf.png" class="img-fluid" alt="" style="width: auto; max-height: 50px;"></span>
        </marquee>
      </section>
            <!-- ======= Clients Section ======= -->
      <!-- <section id="clients" class="section-bg">
        <div class="container">
          <div class="section-title">
            <h2>Trusted by 200+ Companies</h2>
          </div>
          <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="10000">
                <div class="row" data-aos="zoom-out">
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/client-mandiri.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/alfamart.png" class="img-fluid" alt="" style="height: 100px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/logo-indihome.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/bni.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/logo-bsi.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/prodia.png" class="img-fluid" alt="">
                  </div>
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="10000">
                <div class="row" data-aos="zoom-in">
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/kalbe.png" class="img-fluid" alt="" style="height: 74px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/logo-alfamidi.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/informa.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/kai.png" alt="" class="img-fluid" style="height: 50px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/dukcapil.png" class="img-fluid" alt="" style="height: 85px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/bpjs-tk.png" class="img-fluid" alt="" style="height: 60px;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="9000">
                <div class="row" data-aos="zoom-in">
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/logo-sicepat.png" class="img-fluid" alt="" style="height: 84px;">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/nestle.png" class="img-fluid" alt="">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/mega-finance.png" class="img-fluid" alt="">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/indomaret.png" class="img-fluid" alt="">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/baf.png" class="img-fluid" alt="">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/xxi.png" class="img-fluid" alt="">
                  </div>
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="9000">
                <div class="row" data-aos="zoom-in">
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/logo-telkomsel-baru.png" class="img-fluid" alt="">
                  </div>
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/bumn.png" alt="" class="img-fluid" style="height: 84px;">
                  </div>
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/mitra-keluarga.png" class="img-fluid" alt="">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/biofarma.png" class="img-fluid" alt="">
                  </div>
      
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/bank-aladin.png" class="img-fluid" alt="">
                  </div>
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/danone-academy.png" class="img-fluid" alt="" style="height: 88px;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="8000">
                <div class="row" data-aos="zoom-out">
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/bri.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/mcd.png" class="img-fluid" alt="" style="height: 90px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/kfc.png" class="img-fluid" alt="" style="height: 84px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/prudential.png" class="img-fluid" alt="" style="height: 80px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/bi.png" class="img-fluid" alt="" style="height: 84px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/clients/kominfo.png" class="img-fluid" alt="" style="height: 105px;">
                  </div>
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="8000">
                <div class="row" data-aos="zoom-out">
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/pln.png" class="img-fluid" alt="" style="height: 84px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/bei.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/kemenkes.png" class="img-fluid" alt="" style="height: 70px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/ojk.png" class="img-fluid" alt="">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/noice.png" class="img-fluid" alt="" style="height: 50px;">
                  </div>
        
                  <span style="margin-right: 60px;">
                    <img src="assets/img/client-baru/kemenparekraf.png" class="img-fluid" alt="" style="height: 84px;">
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </section> -->
      <!-- End Cliens Section -->
      
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'Clients'
}
/* eslint-enable vue/multi-word-component-names */
</script>

<style scoped>
</style>
