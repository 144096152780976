<template>
  <div class="container-fluid pt-4 pb-5">
    <div class="container">
      <h3 class="title pb-4 pt-4 text-center">Our Solutions for Multiple Industries</h3>
      <div class="row justify-content-center">
        <div class="col-md-4 col-sm-6 d-flex justify-content-center mb-4">
          <div class="card" style="width: 19rem; height:18rem;">
            <div style="display: flex; justify-content: center; width: 100%;">
              <img src="@/assets/public/budget.png" class="card-img-top mt-4" alt="Financial image">
            </div>
            <div class="card-body mb-2">
              <h5>{{ $t('solution_page.Create.Financial') }}</h5>
              <ul class="list-group list-group-flush mx-2">
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.FinancialOne') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.FinancialTwo') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.FinancialThree') }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 d-flex justify-content-center mb-4">
          <div class="card" style="width: 19rem; height:18rem;">
            <div style="display: flex; justify-content: center; width: 100%;">
              <img src="@/assets/public/government.png" class="card-img-top mt-4" alt="Government image">
            </div>
            <div class="card-body mb-2">
              <h5>{{ $t('solution_page.Create.Government') }}</h5>
              <ul class="list-group list-group-flush mx-2">
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.GovernmentOne') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.GovernmentTwo') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.GovernmentThree') }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 d-flex justify-content-center mb-4">
          <div class="card" style="width: 19rem; height:18rem;">
            <div style="display: flex; justify-content: center; width: 100%;">
              <img src="@/assets/public/wholesaler.png" class="card-img-top mt-4" alt="Retail image">
            </div>
            <div class="card-body mb-2">
              <h5>{{ $t('solution_page.Create.Retail') }}</h5>
              <ul class="list-group list-group-flush mx-2">
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.RetailOne') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.RetailTwo') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.RetailThree') }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 d-flex justify-content-center mb-4">
          <div class="card" style="width: 19rem; height:18rem;">
            <div style="display: flex; justify-content: center; width: 100%;">
              <img src="@/assets/public/protection.png" class="card-img-top mt-4" alt="Healthcare image">
            </div>
            <div class="card-body mb-2">
              <h5>{{ $t('solution_page.Create.Healthcare') }}</h5>
              <ul class="list-group list-group-flush mx-2">
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.HealthcareOne') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.HealthcareTwo') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.HealthcareThree') }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 d-flex justify-content-center mb-4">
          <div class="card" style="width: 19rem; height:18rem;">
            <div style="display: flex; justify-content: center; width: 100%;">
              <img src="@/assets/public/logistics.png" class="card-img-top mt-4" alt="Logistics image">
            </div>
            <div class="card-body mb-2">
              <h5>{{ $t('solution_page.Create.Logistics') }}</h5>
              <ul class="list-group list-group-flush mx-2">
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.LogisticsOne') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.LogisticsTwo') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.LogisticsThree') }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 d-flex justify-content-center mb-4">
          <div class="card" style="width: 19rem; height:18rem;">
            <div style="display: flex; justify-content: center; width: 100%;">
              <img src="@/assets/public/hospitality.png" class="card-img-top mt-4" alt="Hospitality image">
            </div>
            <div class="card-body mb-2">
              <h5>{{ $t('solution_page.Create.Hospitality') }}</h5>
              <ul class="list-group list-group-flush mx-2">
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.HospitalityOne') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.HospitalityTwo') }}
                </li>
                <li class="list-group-item">
                  <i class="fas fa-check text-success"></i>
                  {{ $t('solution_page.Create.HospitalityThree') }}
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable vue/multi-word-component-names */

export default {
  name: 'CreatePage'
};
</script>

<style scoped>
.container-fluid {
  font-family: Metrophobic;
  background-color: #AFE1AF;
}
.card {
  background-color: white;
  border-radius: 50px;
}
.list-group-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.list-group {
  font-size: 12px;
  text-align: left;
}
.card-img-top {
  height: 80px;
  width: 80px;
  text-align: center;
  justify-content: center;
  padding-top: 10px;
}
.title {
  color: #027057;
  font-weight: 600;
}
</style>
