<template>
<div class="container-fluid">
  <div class="block">
        <h2 class="title pb-3 pt-3">Blog</h2>
      </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12 text-left mt-3">
      <h2 class="heading">Gandeng M Cash, Kemenkes Luncurkan Sistem Pelacakan Covid-19 Lewat WA dan Aplikasi PeduliLindungi</h2>
      <p>
        Kementerian Kesehatan RI bermitra dengan PT M Cash Integrasi Tbk (MCAS) dan anak perusahaannya PT Dam Korporindo Digital (DKD) menerapkan sistem pelacakan kontak Covid-19 digital nasional, melalui Chatbot WhatsApp dengan fitur PeduliLindungi.
        Chatbot WhatsApp tersebut dapat diakses secara nasional bagi masyarakat Indonesia untuk check in dan check out dari area publik dalam upaya untuk meningkatkan pelacakan dan penahanan wabah Covid-19 dimana data penggunaan sistem PeduliLindungi akan tetap terjaga privasi dan kerahasiaannya.
      </p>
    </div>
    <div class="col-12 text-left mt-3">
      <h2 class="heading">Indonesia, WhatsApp boost vaccination rollout among medical workers</h2>
      <p>
        The Health Ministry has partnered with WhatsApp to provide a new chatbot service to facilitate registration of the COVID-19 vaccination program across the country. Launched on Jan. 15, two days after the vaccination rollout, this is the first WhatsApp chatbot worldwide that helps medical workers to connect to their nearest health facilities to receive the jab. The Jakarta Post’s Budi Sutrisno sat down with WhatsApp Asia Pacific director of public policy Clair Deevy to learn more about the service.
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
 /* eslint-disable vue/multi-word-component-names */
  name: 'Blog',
  
};
</script>

<style scoped>
.container {
  font-family: metrophobic;
}
.container-fluid {
  font-family: metrophobic;
}
.text-left {
  text-align: left;
}
.block  {
  background-color:#009C77;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;

}
.title {
  color:white;
  width:100%;
}
.heading {
  color:#009C77;
}

</style>
